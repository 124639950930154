import { RouteComponentProps, Router } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import { withErrorBoundary } from '@/bits';
import { BaseWrapper, Page } from '@/components';
import { assert } from '@/utils/error';
import { useGetOperatorPath } from '@/utils/operators';
import { PageTemplate } from './__generated__/PageTemplate';

const NotFound: React.FC<RouteComponentProps> = () => (
  <p>Sorry, nothing here</p>
);

const Template = ({
  data: { sanityPage },
  pageContext: { routerPath },
}: {
  data: PageTemplate;
  pageContext: { routerPath: string };
}) => {
  assert(sanityPage, 'missing sanityPage');

  const getOperatorPath = useGetOperatorPath();

  const redirectPath = sanityPage.redirectToOperatorPage?.path?.current;
  const operatorRedirectPath = redirectPath && getOperatorPath(redirectPath);

  useEffect(() => {
    if (operatorRedirectPath) {
      navigate(operatorRedirectPath, { replace: true });
    }
  }, [operatorRedirectPath]);

  if (sanityPage.redirectToOperatorPage?.path) {
    return null;
  }

  return (
    <BaseWrapper>
      <Router basepath="/" primary={false}>
        <Page page={sanityPage} path={routerPath} />
        <NotFound default />
      </Router>
    </BaseWrapper>
  );
};

export default withErrorBoundary(Template);

export const query = graphql`
  query PageTemplate($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...PageFragment
      redirectToOperatorPage {
        path {
          current
        }
      }
    }
  }
`;
